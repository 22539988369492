<template>
  <div class="_wrap">
    <div class="_swiper">
      <el-carousel height="955px" :interval="6000" arrow="never">
        <el-carousel-item
          v-for="(item, index) in $store.state.homeData.banner"
          :key="index"
        >
          <img
            v-if="item.video == null || item.video == ''"
            :src="$IMG_URL + item.image"
            alt="八路军驻洛办事处纪念馆-官方网站"
            @click="goOther(item)"
            :class="{ _cur: item.url != '' && item.url != null }"
          />

          <video
            ref="videoRef"
            v-else
            autoplay
            muted
            class="_video"
            :src="$IMG_URL + item.video"
            controls
            oncontextmenu="return false"
            controlslist="nodownload noplaybackrate"
            disablePictureInPicture
            :poster="$IMG_URL + item.cover"
          ></video>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="_content">
      <div class="_nov" v-if="$store.state.webData.other_data">
        <div class="_auto">
          <div class="_left">
            <div class="_h4">开放时间</div>
            <div class="_tips">
              八路军驻洛办事处纪念馆,除法定节假日外全年实行周一闭馆,周二至周日开馆
            </div>
            <div class="_time">
              <div>
                <p>{{ $store.state.webData.other_data.open_time }}</p>
                <span>开馆时间</span>
              </div>
              <i>
                <img
                  src="../assets//image/home-img/home_bg_time.png"
                  alt="八路军驻洛办事处纪念馆-官方网站"
                />
              </i>
              <div>
                <p>{{ $store.state.webData.other_data.stop_time }}</p>
                <span>停止入场时间</span>
              </div>
              <i>
                <img
                  src="../assets//image/home-img/home_bg_time.png"
                  alt="八路军驻洛办事处纪念馆-官方网站"
              /></i>
              <div>
                <p>{{ $store.state.webData.other_data.close_time }}</p>
                <span>闭馆时间</span>
              </div>
            </div>
          </div>

          <div class="_left1">
            <div class="_rx">
              <p class="_zxrx">咨询热线</p>
              <p class="_tel">{{ $store.state.homeData.hotline }}</p>
            </div>

            <div class="_xz">
              <div class="_li" @click="goDetailTop('游览须知')">
                <img
                  src="@/assets/image/home-img/home_icon_youlan.png"
                  alt="八路军驻洛办事处纪念馆-官方网站"
                />
                <p>游览须知</p>
              </div>
              <div class="_li" @click="goDetailTop('交通路线')">
                <div class="_imgBox">
                  <img
                    src="@/assets/image/home-img/home_icon_jiaotong.png"
                    alt="八路军驻洛办事处纪念馆-官方网站"
                  />
                </div>
                <p>交通指南</p>
              </div>
              <div class="_li" @click="goDetailTop('讲解服务')">
                <div class="_imgBox">
                  <img
                    src="@/assets/image/home-img/jjfw.png"
                    alt="八路军驻洛办事处纪念馆-官方网站"
                  />
                </div>
                <p>讲解服务</p>
              </div>
            </div>
          </div>

          <div class="_right">
            <div class="_div">
              <img src="@/assets/image/home-img/bg_gongzhonghao.png" alt="" />
              <p>微信公众号</p>
            </div>
            <img
              src="@/assets/image/home-img/bg_home_code_line.png"
              alt=""
              class="_line"
            />
            <div class="_div">
              <img src="@/assets/image/home-img/bg_douyin.png" alt="" />
              <p>官方抖音号</p>
            </div>
            <img
              src="@/assets/image/home-img/bg_home_code_line.png"
              alt=""
              class="_line"
            />

            <div class="_div">
              <img src="@/assets/image/home-img/bg_shipinhao.png" alt="" />
              <p>微信视频号</p>
            </div>
          </div>

          <p
            class="_gengduo"
            @click="
              $router.push({
                path: '/homeVideo',
              })
            "
          >
            <img src="@/assets/image/home-img/icon_shipin_more.png" alt="" />
            <span>更多精彩视频</span>
          </p>
        </div>
      </div>

      <div class="_hh" @click="goHH">
        <img src="../assets/image/home-img/hh.png" alt="">
      </div>

      <div class="_ggCont">
        <div class="_auto">
          <div class="_left">
            <div class="_top">
              <div class="_tabs">
                <div
                  :class="{ _li: true, _active: current == index }"
                  v-for="(item, index) in tabList"
                  :key="index"
                  @click="changeTab(item, index)"
                >
                  {{ item.name }}
                </div>
              </div>
              <router-link
                :to="{ path: '/information', query: { tit: jumpName } }"
                style="color: #333333"
                class="_mmm"
              >
                <div class="_more">更多/more</div>
              </router-link>
            </div>

            <div class="_cont" v-if="listCont.length > 0">
              <!-- <div class="_contImg">
                <img :src="$IMG_URL + listCont[0].cover" alt="八路军驻洛办事处纪念馆-官方网站">
              </div> -->
              <div class="_cont_nr">
                <div
                  class="_li"
                  v-for="(item, index) in listCont"
                  :key="index"
                  @click="goThreePage(item, '资讯')"
                >
                  <div class="_rq">
                    <p class="_rizi">{{ item.release_date.substring(5) }}</p>
                    <p>{{ item.release_date.substring(0, 4) }}</p>
                  </div>
                  <div class="_li_cont">
                    <h4>{{ item.title }}</h4>
                    <p class="_des">{{ item.introduction }}</p>
                  </div>
                </div>
              </div>
            </div>
            <router-link
              :to="{ path: '/information', query: { tit: jumpName } }"
              style="color: #333333"
              class="_mmm1"
            >
              <div class="_more">更多/more</div>
            </router-link>
          </div>
          <div class="_right">
            <div class="_top">
              <div class="_tit">
                <h4 style="color: #666; font-weight: normal">社教活动</h4>
                <router-link to="/moreEdu?tit=社教活动" style="color: #333333">
                  <p class="_more">更多/more</p>
                </router-link>
              </div>
            </div>

            <div class="_cont" v-if="activtyList.length > 0">
              <div class="_img">
                <img
                  :src="$IMG_URL + activtyList[0].cover"
                  alt="八路军驻洛办事处纪念馆-官方网站"
                  @click="goThreePage(activtyList[0], '社教')"
                />
              </div>
              <div class="_list">
                <div
                  class="_list_li"
                  v-for="(item, index) in activtyList"
                  :key="index"
                  @click="goThreePage(item, '社教')"
                >
                  <h4>{{ item.title }}</h4>
                  <p class="_impact">{{ item.release_date }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 八办视频 -->

      <div class="_veCont">
        <div class="_auto">
          <div class="_vetit">
            <div class="_vel">
              <img
                src="../assets/image/home-img/spt.png"
                alt="视觉八办"
                class="_sjbb"
              />
              <img src="../assets/image/home-img/spt1.png" alt="" class="_x" />
              <span>重温洛八办峥嵘岁月，传承不屈精神</span>
            </div>
            <div class="_ver">
              <img
                src="../assets/image/home-img/spt2.png"
                alt=""
                @click="
                  $router.push({
                    path: '/homeVideo',
                  })
                "
              />
            </div>
          </div>

          <div class="_veList">
            <div class="swiper mySwiper">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  style="margin-top: 10px"
                  v-for="(item, index) in videoList"
                  :key="index"
                >
                  <div class="_li" @click="lookVideo(item)">
                    <img :src="$IMG_URL + item.cover" alt="" class="_liimg" />
                    <img
                      src="../assets/image/home-img/bf.png"
                      alt=""
                      class="_bf"
                    />
                  </div>

                  <div class="_h4">{{ item.title }}</div>
                </div>
              </div>

              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="_zpCont">
        <div class="_auto">
          <div class="_left or-container">
            <div
              class="box"
              :class="eleindex == index ? 'eleactive' : ''"
              v-for="(item, index) in picList"
              :key="index"
              @click="goDetail(item.text)"
              @mouseenter="enter(index)"
              @mouseleave="out(index)"
            >
              <img
                :src="item.img"
                class="_mainImg"
                alt="八路军驻洛办事处纪念馆-官方网站"
              />

              <img :src="item.title" class="_titImg" :alt="item.text" />
            </div>
          </div>
          <div class="_right">
            <div
              class="_r1"
              v-for="(item, index) in vrList"
              :key="index"
              @click="goDetailOther(item)"
            >
              <img
                :src="$IMG_URL + item.image"
                alt="八路军驻洛办事处纪念馆-官方网站"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <mainFooter ref="mainFooterRef"></mainFooter>

    <videoBox ref="videoBoxRef"></videoBox>
  </div>
</template>

<script>
import Swiper from "swiper";
import {
  getSEA,
  hits,
  videoList,
  videoHits,
  tempExhibit,
  getVr,
} from "@/api/getData";
import videoBox from "@/components/diaVideo/dieVideo.vue";
export default {
  components: { videoBox },
  data() {
    return {
      jumpName: "新闻资讯",

      title: "八路军驻洛办事处纪念馆-官方网站",
      // keywords: this.$keywords,
      // desc: this.$description,
      current: -1,

      eleindex: 0,
      styleDes: {
        height: 0,
      },
      current: 0,
      tabList: [
        {
          name: "新闻资讯",
        },
        {
          name: "通知公告",
        },
        {
          name: "党建工作",
        },
      ],
      banner: [],
      activtyList: [],
      listCont: [],
      information: [],
      research_activities: [],
      total: 100,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      videoList: [],
      queryParams: {
        page: 1,
        page_num: 1,
      },
      ls_list: [],
      vrList: [],
      seen: false,
      picList: [
        {
          img: require("../assets/image/home-img/bg_guancang.png"),
          text: "馆藏珍品",
          title: require("../assets/image/home-img/bg_guancang_title.png"),
        },
        {
          img: require("../assets/image/home-img/bg_chenlie.png"),
          text: "基本陈列",
          title: require("../assets/image/home-img/bg_chenlie_title.png"),
        },
        {
          img: require("../assets/image/home-img/bg_zhanlan.png"),
          text: "临时展览",
          title: require("../assets/image/home-img/bg_zhanlan_title.png"),
        },
        {
          img: require("../assets/image/home-img/bg_wenchuang.png"),
          text: "文创",
          title: require("../assets/image/home-img/bg_wenchuang_title.png"),
        },
      ],
    };
  },
  // // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: this.title,
      // meta: [
      //   { name: "keywords", content: this.keywords },
      //   { name: "description", content: this.desc },
      // ]
    };
  },

  created() {
    this.getSEA();
  },

  watch: {
    "$store.state.homeData": function (newVal, oldVal) {
      console.log("store中的数据已更新");

      this.homeData = this.$store.state.homeData;
      console.log(this.footerData);
      console.log(this.homeData);
    },
  },
  async mounted() {
    this.menuSeen();
    await this.getVideo();
    await this.getList();
    await this.getVr();
    let homeData = this.$store.state.homeData;
    console.log(homeData);
    this.banner = homeData.banner;
    this.information = homeData.information;
    this.listCont = this.information.news;
    this.research_activities = homeData.research_activities;

    let slidesPerView, spaceBetween;
    if (this.seen) {
      slidesPerView = 3;
      spaceBetween = 60;
    } else {
      slidesPerView = 1;
      spaceBetween = 0;
    }

    var swiper = new Swiper(".mySwiper", {
      slidesPerView: slidesPerView,
      spaceBetween: spaceBetween,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    window.scrollTo({
      top: 0,
      behavior: "smooth", // 平滑滚动
    });

    if (this.$refs.videoRef) {
      document.querySelector("._video").play();
    }
  },

  methods: {
    menuSeen() {
      // 屏幕尺寸
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 415) {
        this.seen = true;
      } else {
        this.seen = false;
      }
    },
    async getVr() {
      await getVr().then((res) => {
        console.log(res);
        this.vrList = res.data;
      });
    },
    goOther(item) {
      console.log(item);
      if (item.url != "" && item.url != null) {
        window.open(item.url);
      }
    },
    // 临时展览
    async getList() {
      await tempExhibit(this.queryParams).then((res) => {
        console.log(res);
        this.ls_list = res.data.data;
        const obj = {
          img: this.$IMG_URL + this.ls_list[0].cover,
          text: "临时展览",
          title: require("../assets/image/home-img/bg_zhanlan_title.png"),
        };
        this.$set(this.picList, 2, obj);
      });
    },
    lookVideo(item) {
      const params = {
        id: item.id,
      };
      videoHits(params).then(() => {
        this.$refs.videoBoxRef.edit(item);
      });
    },
    goDetailTop(name) {
      this.$router.push({
        path: "/visit",
        query: {
          tit: name,
        },
      });
    },
    enter(i) {
      console.log(i);
      this.eleindex = i;
    },
    goDetailOther(item) {
      window.open(item.url);
    },

    out(i) {},

    async getVideo() {
      const params = {
        page: 1,
        page_num: 6,
        type: 2,
      };
      await videoList(params).then((res) => {
        console.log(res);
        this.videoList = res.data.all.data;
      });
    },

    goThreePage(item, name) {
      let url = location.origin;
      if (name == "资讯") {
        if (item.link != null && item.link != "") {
          const params = {
            type: 1,
            id: item.id,
          };
          hits(params);
          window.open(item.link);
        }

        window.open(`${url}/informationDetail?id=${item.id}&breadName=${this.jumpName}`);
      } else if (name == "社教") {
        if (item.link != null && item.link != "") {
          const params = {
            type: 2,
            id: item.id,
          };
          hits(params);
          window.open(item.link);
        }

        window.open(`${url}/educationDetail?id=${item.id}&breadName=社教活动`);
      }
    },
    // 社教活动
    async getSEA() {
      const params = {
        page: 1,
        page_num: 2,
      };
      await getSEA(params).then((res) => {
        console.log(res);
        this.activtyList = res.data.data;
      });
    },
    goDetail(name) {
      const obj = {
        馆藏珍品: "/takeCare",
        基本陈列: "/exhibit?tit=基本陈列",
        临时展览: "/exhibit?tit=临时展览",
        文物交流: "/exhibit?tit=文物交流",
        文创: "/industries",
        红色游径: "",
        全景漫游: "",
      };
      for (let i in obj) {
        if (i == name) {
          if (i == "红色游径") {
            window.open(this.$store.state.homeData.trails);
          } else if (i == "全景漫游") {
            window.open(this.$store.state.webData.paranoma_roaming);
          } else {
            this.$router.push(obj[i]);
          }
        }
      }
    },
    changeTab(item, index) {
      this.current = index;

      if (index == 0) {
        this.jumpName = "新闻资讯";

        this.listCont = this.information.news;
      } else if (index == 1) {
        this.jumpName = "通知公告";
        this.listCont = this.information.announcement;
      } else {
        this.jumpName = "党建工作";

        this.listCont = this.information.party;
      }
    }, 

    goHH(){
      window.open('http://www.ncha.gov.cn/col/col2376/index.html')
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../common/mixin.scss";

@mixin allBg($layout) {
  background-image: url($layout);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

@mixin flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin ba_cont {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  border: 1px solid #fff;
  transform: rotate(45deg);
}

._hh{
  width: 75%;
  margin: 0 auto;
  margin-top: 20px;
  cursor: pointer;

  @include respondTo("phone"){
    width: 100%;
    padding: 0 8px;
  }
  img{
    width: 100%;
  }
}
._cur {
  cursor: pointer;
}

._more {
  border: 1px solid #d8d8d8;
  padding: 5px 10px;
  border-radius: 2px;
  cursor: pointer;
  color: #333333;
}

._more:hover {
  color: $hover-color;
}

._swiper {
  width: 100%;

  ._video {
    width: 100%;
    // height: 100%;
  }
}

._content {
  width: 100%;
  background-color: #f5f5f5;
  // background-color: red;
}

._auto {
  width: 75%;
  margin: 0 auto;
}

._gengduo {
  position: absolute;
  top: -75px;
  left: 0;
  width: 159px;
  height: 46px;
  background-image: url("../assets/image/home-img/bg_shipin_more.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
  cursor: pointer;

  img {
    width: 32px;
  }
}

._nov ._auto {
  @include flex;
  margin-top: -120px;
  position: relative;
  z-index: 999;

  > div {
    height: 220px;
    color: white;
    padding: 20px 20px;
  }

  ._left {
    @include allBg("../assets/image/home-img/time_bg.png");
    background-size: cover;
    width: 42%;
    padding: 20px 0px 20px 20px;

    ._h4 {
      font-weight: bold;
      font-size: 36px;
      display: flex;
      align-items: center;
    }

    ._h4::before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      transform: rotate(45deg);
      // border: 1px solid #fff;
      background-color: #fff;
      margin-right: 10px;
    }

    ._tips {
      font-size: 16px;
      color: #ffeddf;
      // margin: 20px 0;
      margin-top: 8px;
      margin-bottom: 13px;
      margin-left: 15px;
    }

    ._time {
      display: flex;
      align-items: flex-start;
      margin-left: 20px;

      > div {
        text-align: center;
      }

      p {
        font-size: 42px;
        font-family: Impact;
        color: #fff1e6;
        letter-spacing: 2px;
      }

      i {
        font-style: normal;
        margin: 0 20px;
        font-size: 32px;
      }
    }
  }

  ._left1 {
    @include allBg("../assets/image/home-img/tel_bg.png");
    background-size: cover;
    width: 23%;
    padding-top: 18px;

    ._rx {
      margin-bottom: 20px;

      ._zxrx {
        font-size: 20px;
      }

      ._tel {
        font-size: 38px;
        // letter-spacing: 1px;
        border-bottom: 1px dotted #e7bc9a;
        padding-bottom: 10px;
        font-family: Impact;
      }
    }

    ._xz {
      display: flex;
      justify-content: space-between;
      align-items: center;

      // ._li:hover p
      // {
      //   color: $hover-color;
      // }

      ._li {
        cursor: pointer;
        text-align: center;

        ._imgBox {
          height: 48px;
        }

        p {
          color: #ffe5d0;
          font-size: 16px;
        }
      }
    }
  }

  ._right {
    @include allBg("../assets/image/home-img/bg_home_code.png");
    width: 35%;
    background-size: cover;
    cursor: pointer;
    display: flex;
    align-items: center;

    ._div {
      width: 120px;

      img {
        width: 100%;
      }

      p {
        text-align: center;
      }
    }

    ._line {
      margin: 0 20px;
    }
  }
}

._ggCont ._auto {
  display: flex;
  justify-content: space-between;
  margin: 40px auto;

  ._mmm1{
    display: none;
  }

  ._left {
    background-color: #fff;
    padding: 20px 20px;
    width: 70%;

    ._top {
      @include flex;

      ._tabs {
        display: flex;
        align-items: center;

        // ._li:hover{
        //   color: $hover-color;
        // }
        ._li {
          padding: 0 27px;
          font-size: 36px;
          color: #666666;
          cursor: pointer;
        }

        ._active {
          color: #b41e1e;
          position: relative;
        }

        ._active::before {
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          transform: rotate(45deg);
          border: 1px solid #b41e1e;
          position: absolute;
          left: 10px;
          top: 20px;
        }
      }
    }

    ._cont {
      // @include flex;
      display: flex;

      margin-top: 40px;

      ._contImg {
        width: 30%;
        height: 400px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      ._cont_nr {
        width: 100%;
        margin-left: 30px;

        ._li {
          border-bottom: 1px solid #eeeeee;
          padding: 20px 0;
          cursor: pointer;
          display: flex;
          // justify-content: space-between;

          // align-items: center;
          ._li_cont {
            width: 75%;
            margin-left: 40px;
            overflow: hidden;
            ._des {
              // transform: translateY(-50px);
              opacity: 0;
              transition: all 0.5s;
              overflow: hidden;
            }
          }

          ._li_cont:hover h4 {
            color: $hover-color;
          }

          ._li_cont:hover ._des {
            // transform: translateY(0);
            opacity: 1;
          }

          h4 {
            color: #333;
            margin-bottom: 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 18px;
            font-weight: bold;
            width: 100%;
          }

          p {
            color: #999999;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            /* 设置最大显示行数 */
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            font-size: 16px;
            width: 100%;
          }

          ._rq {
            width: 15%;
            // text-align: right;
            // margin-right: 10px;
            // font-size: 20px;
            letter-spacing: 1px;
            color: #999;
            border-right: 1px solid #d6d6d6;

            ._rizi {
              color: #333333;
              font-size: 24px;
            }
          }
        }
      }
    }
  }

  ._right {
    background-color: #fff;
    width: 28%;

    ._top {
      margin-bottom: 10px;
      padding: 20px;

      ._tit {
        @include flex;

        h4 {
          font-size: 36px;
          font-weight: bold;
          display: flex;
          align-items: center;
        }

        h4::before {
          content: "";
          display: inline-block;
          width: 5px;
          height: 5px;
          border: 1px solid #b41e1e;
          transform: rotate(45deg);
          margin-right: 10px;
        }
      }
    }

    ._cont {
      ._img {
        width: 100%;
        height: 228px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
          object-fit: cover;
          transition: all 0.5s;
        }
      }

      img:hover {
        transform: scale(1.1);
      }

      ._list {
        // padding: 20px;
        padding: 0 20px;

        ._list_li:hover h4 {
          color: $hover-color;
        }

        ._list_li {
          // margin-bottom: 20px;
          padding: 20px 0;
          border-bottom: 1px dotted #e4e4e4;
          cursor: pointer;

          h4 {
            font-weight: bold;
            letter-spacing: 1px;
            margin-bottom: 10px;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 18px;
          }

          p {
            color: #999999;
            letter-spacing: 1px;

            // font-weight: normal;
          }
        }
      }
    }
  }
}

._zpCont {
  width: 100%;
  background-image: url("../assets/image/home-img/bg_home_dibu.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  margin-top: -95px;
  position: relative;
  z-index: 1;
}

._zpCont ._auto {
  // display: flex;
  // justify-content: space-between;
  margin-bottom: 92px;
  margin-top: 160px;

  /*手风琴样式*/
  .or-container {
    display: flex;
    width: 100%;
    // padding: 4% 2%;
    box-sizing: border-box;
    height: 300px;
  }

  .or-container:before {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .box {
    flex: 1;
    overflow: hidden;
    transition: 0.5s;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
    line-height: 0;
    cursor: pointer;
    position: relative;
  }

  .box ._titImg {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .box > ._mainImg {
    width: 100%;
    // height: calc(100% - 10vh);
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    transition: 0.5s;
  }

  .eleactive {
    flex: 1 1 11.3%;
  }

  .eleactive > ._mainImg {
    width: 100%;
    height: 100%;
  }

  /*end*/

  ._right {
    // width: 468px;
    display: flex;
    justify-content: space-between;
    margin-top: 58px;

    > div {
      // height: 33.3333%;
      // width: 707px;
      height: 130px;
      cursor: pointer;
      color: white;
      // font-size: 36px;
      overflow: hidden;
      margin-right: 10px;

      img {
        width: 100%;
      }

      > h4 {
        margin-top: 15%;
        letter-spacing: 1px;
      }
    }

    > div:last-child {
      margin-right: 0;
    }

    img {
      width: 468px;
      height: 100%;
      // object-fit: cover;
    }

    ._r2 {
      // margin: 24px 0;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: all 0.8s ease;
}

.v-enter,
.v-leave-to {
  transform: translateX(100px);
  opacity: 0;
}

::v-deep .el-carousel__indicators--horizontal {
  left: 74.5%;
  bottom: 120px;
  @include respondTo("phone") {
      bottom: 5px;
      left: auto;
      transform: translateX(0);
      width: 100%;
      text-align: center;
  }
}


::v-deep .el-carousel__button {
  height: 8px;
}

@media (max-width: 1880px) {
  ._nov {
    ._left1 {
      ._rx {
        ._tel {
          font-size: 34px;
        }
      }
    }
  }
}

::v-deep ._swiper video::-webkit-media-controls {
  display: none !important;
}

._veCont {
  background-image: url("../assets/image/home-img/spbg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 770px;
  overflow: hidden;
  position: relative;
  z-index: 2;

  ._vetit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;

    ._vel {
      display: flex;
      align-items: center;

      ._x {
        margin: 0 16px;
      }

      span {
        font-size: 24px;
        color: #333;
      }
    }

    ._ver {
      cursor: pointer;
    }
  }

  ._veList {
    width: 100%;
    margin-top: 60px;
    height: 440px;

    .swiper {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;

      ._li {
        width: 440px;
        height: 272px;
        position: relative;
        cursor: pointer;
        border-radius: 8px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        box-shadow: 0 5px 20px 1px rgba(49, 49, 49, 0.2);

        ._liimg {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        ._bf {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -24px;
          margin-left: -24px;
        }
      }

      ._li:hover {
        -ms-transform: translateY(-10px);
        -webkit-transform: translateY(-10px);
        -moz-transform: translateY(-10px);
        -o-transform: translateY(-10px);
        transform: translateY(-10px);
        z-index: 1002;
        position: relative;
      }

      ._h4 {
        margin-top: 20px;
        color: #333;
        font-size: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

::v-deep ._veList .swiper-pagination {
  bottom: 20px;

  .swiper-pagination-bullet-active {
    background: #b41e1e;
  }
}

::v-deep ._veList .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
}

::v-deep ._veList .swiper-button-prev {
  background-image: url("../assets/image/home-img/spl.png");
  background-size: 100 100%;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  top: 90%;
  left: 40%;
  z-index: 20;
}

::v-deep ._veList .swiper-button-prev:after {
  content: "";
}

::v-deep ._veList .swiper-button-next {
  background-image: url("../assets/image/home-img/spr.png");
  background-size: 100 100%;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  top: 90%;
  right: 40%;
  z-index: 20;
}

::v-deep ._veList .swiper-button-next:after {
  content: "";
}

::v-deep .el-carousel__container {
  @include respondTo("phone") {
    height: 200px !important;
    margin-top: 70px;

    .el-carousel__item {
      height: 100%;
      width: 100%;

      img {
        height: 100%;
        width: 100%;

        object-fit: cover;
      }
    }
  }
}

@include respondTo("phone") {
  ._content {
    ._nov {
      ._auto {
        margin-top: -15px;
        display: block;
        padding: 0 8px;

        > div {
          height: auto;
          padding: 0;
        }

        ._left {
          width: 100%;
          padding: 10px;
          ._h4 {
            font-size: 22px;
          }
          ._tips {
            margin-bottom: 10px;
          }

          ._time {
            margin-left: 10px;
            justify-content: space-around;
            p {
              font-size: 26px;
            }
            span {
              font-size: 14px;
            }
            i {
              margin: 0;
              font-size: 14px;
            }
          }
        }
        ._left1 {
          width: 100%;
          padding-bottom: 10px;
          ._rx {
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px dotted #e7bc9a;

            padding: 10px 0;
            ._zxrx {
              font-size: 16px;
              margin-right: 10px;
            }
            ._tel {
              font-size: 24px;
              letter-spacing: 2px;
              border: none;
              padding-bottom: 0;
            }
          }

          ._xz {
            padding: 0 20px;
          }
        }
        ._right {
          width: 100%;
          padding: 8px;
          justify-content: space-between;
          ._div {
            width: 80px;
          }
          ._line {
            margin: 0 10px;
          }
        }
      }
    }

    ._ggCont {
      width: 100%;
      ._auto {
        margin: 10px auto;
        padding: 0 8px;
        display: block;
        ._left {
          width: 100%;
          padding: 0;
          ._top {
            display: block;
            ._tabs {
              justify-content: space-around;
              padding: 10px 0;
              ._li {
                padding: 0;
                font-size: 22px;
              }
              ._active::before {
                top: 10px;
                left: -13px;
              }
            }
            ._mmm {
              display: none;
            }
          }

          ._cont {
            margin-top: 0;
            ._cont_nr {
              margin-left: 0;
              padding: 0;
              ._li {
                padding: 10px;

                align-items: center;
                ._rq {
                  width: 18%;
                  ._rizi {
                    font-size: 18px;
                  }
                }

                ._li_cont {
                  margin-left: 20px;
                }
              }
            }
          }

          ._mmm1 {
            display: inline-block;
            width: 100%;
            height: 36px;
            background: #f3f1eb;
            margin-top: 15px;
            ._more {
              border: none;

              color: #ad8d4f;
              text-align: center;
            }
          }
        }

        ._right {
          width: 100%;
          ._top {
            padding: 10px;
            margin-bottom: 0;
            ._tit {
            }
            h4 {
              font-size: 22px;
            }
          }
          ._cont {
            ._list {
              padding: 0 10px;
            }
          }
        }
      }
    }

    ._veCont {
      height: 490px;
      ._auto {
        padding: 0 8px;

        ._vetit {
          margin-top: 20px;
          ._vel {
            margin-right: 8px;
            ._sjbb {
              width: 80px;
            }
            ._x {
              margin: 0 5px;
              width: 30px;
            }

            span {
              font-size: 15px;
            }
          }
          ._ver{
            img{
              width: 80px;
            }
          }
        }

        ._veList {
          margin-top: 20px;
          .swiper{
            ._li{
              width: 100%;
            }
            ._h4{
              margin-top: 10px;
            }
          }
          .swiper-pagination{
            bottom: 50px;
          }

          .swiper-button-prev{
            bottom: 35px;
            top: auto;
            left: 20px;
          }
          .swiper-button-next{
            bottom: 35px;
            top: auto;
            right: 20px;
          }
        }
      }
    }

    ._zpCont{
      margin-top: 20px;
      ._auto{
        margin-top: 0;
        padding: 0 8px;
        margin-bottom: 20px;
        ._left{

        }
        ._right{
          display: block;
          margin-top: 20px;
          >div{
            height: auto;
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>

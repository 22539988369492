import { render, staticRenderFns } from "./dieVideo.vue?vue&type=template&id=44433b92&scoped=true"
import script from "./dieVideo.vue?vue&type=script&lang=js"
export * from "./dieVideo.vue?vue&type=script&lang=js"
import style0 from "./dieVideo.vue?vue&type=style&index=0&id=44433b92&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44433b92",
  null
  
)

export default component.exports
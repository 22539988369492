<template>
    <el-dialog title="" :visible.sync="videoFlag" width="45%" :before-close="close" :close-on-click-modal="false"
        :close-on-press-escape="false" @open="open">
        <div class="_videoBox">
            <video ref="myVideo" :src="videoSrc" controls id="videoname" autoplay
                controlslist="nodownload noremoteplayback"></video>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            videoFlag: false,
            videoSrc: "",
        }
    },
    methods: {
        open() {
            console.log("执行open")

            setTimeout(() => {



                this.$refs.myVideo.play()

            }, 0);
        },
        close() {
            this.$refs.myVideo.pause()

            this.videoFlag = false
        },

        edit(data) {
            console.log("执行edit")
            this.videoSrc = this.$IMG_URL + data.video_src
            this.videoFlag = true
        },

    }

}
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

._videoBox
{
    width: 100%;

    video
    {
        width: 100%;
    }
}


::v-deep .el-dialog__header
{
    padding: 0;

}

::v-deep .el-dialog__body
{
    padding-top: 40px;
    padding-bottom: 10px;
}

::v-deep .el-dialog__headerbtn
{
    top: 10px;
    right: 10px;

}

::v-deep .el-dialog__headerbtn .el-dialog__close
{
    font-size: 22px;
}

::v-deep .el-dialog{
 @include respondTo('phone'){
    width: 95% !important;
    margin-top: 30vh !important;
 }
}
</style>